import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Typography,
  Row,
  Table,
  Tooltip,
  Modal,
  Input,
  Slider,
  Checkbox,
} from "antd";
import { CopyOutlined, CheckCircleOutlined } from "@ant-design/icons";
import "./App.css";
import {
  BASE_URL,
  DOCS_URL,
  addNewSensitivitySettingDomain,
  deleteApplication,
  deleteGoogleAuthFile,
  deleteSensitivitySettingDomain,
  getAllDomainsForSensitivitySettings,
  getGoogleAuthFile,
  getSensitivitySettingForApp,
  listAllApplications,
  regenerateAPIKey,
  updateSensitivitySettingDomain,
  uploadGoogleAuthFile,
  upsertSensitivitySettingForApp,
} from "./Utils/ApiActions";
import SideDrawer from "./Components/SideDrawer";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import moment from "moment";
import NewAppModal from "./Components/NewAppModal";
import { MdChevronRight } from "react-icons/md";
import { ViewApiDocsModal } from "./Components/ViewAPIDocsModal";
import { CodeBlock, dracula } from "react-code-blocks";
import { BsBoxArrowUpRight } from "react-icons/bs";
import copy_icon from "./assets/copy_icon.png";
import verifyIcon from './assets/verify-icon.svg'
import remove_icon from "./assets/remove_icon.png";
import DeleteTypeModal from "./Components/LockrDeleteModal";

const { confirm } = Modal;

const domainRegex = new RegExp(
  /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/,
  "i"
);

export const SENSITIVITY_SETTINGS = {
  STRICT: "STRICT",
  SOMEWHAT_LINIENT: "SOMEWHAT_LINIENT",
  LINIENT: "LINIENT",
};

export function DomainTick({ height = 8, width = 10 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 8"
      fill="none"
    >
      <path d="M1 3.5L3.5 6L8.5 1" stroke="#50E8B1" stroke-width="1.5" />
    </svg>
  );
}

export function WarningModal({ visible, onLeave, onStay }) {
  return (
    <Modal
      centered
      transitionName=""
      maskTransitionName=""
      maskStyle={{ backdropFilter: "blur(8px)" }}
      width={776}
      className="appCreatedModal"
      closable={false}
      bodyStyle={{
        height: 236,
        paddingTop: 0,
        backgroundColor: "#353738",
        paddingLeft: 60,
      }}
      visible={visible}
      footer={null}
      onCancel={onStay}
    >
      <div style={{ display: "inline-block", width: 550 }}>
        <Typography.Text>
          You currently have unsaved changes! Are you sure you want to exit
          without saving?
        </Typography.Text>
      </div>
      <div
        style={{
          display: "inline-block",
          position: "absolute",
          marginBottom: 24,
          right: 40,
          cursor: "pointer",
        }}
      >
        <img
          onClick={() => {
            onStay();
          }}
          alt="warning-close"
          src={require("./assets/modal-close.png")}
        ></img>
      </div>
      <div style={{ marginTop: 40 }}></div>
      <Button
        style={{ width: 220 }}
        className="sign-in"
        block
        onClick={() => {
          onStay();
        }}
        type="primary"
      >
        <label style={{ cursor: "pointer" }}>{"Stay on this page"}</label>
      </Button>
      <Button
        // style={{ width: 220, marginLeft: 24 }}
        // className="sign-in"
        style={{
          marginLeft: 24,
          width: 220,
          height: 56,
          background: "transparent",
          borderColor: "#61CEF7",
          color: "#61CEF7",
          fontFamily: "Montserrat",
          fontSize: 16,
          fontWeight: "bold",
        }}
        block
        onClick={() => {
          onLeave();
        }}
        type="default"
      >
        <label style={{ cursor: "pointer" }}>{"Leave this page"}</label>
      </Button>
    </Modal>
  );
}

export function NewVendorForm({ visible, onAdd, onClose, loading }) {
  const [displayWarningModal, setDisplayWarningModal] = useState(false);
  const [vendor, setVendor] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setShowError(false);
  }, [vendor]);

  function onAddDomain() {
    if (domainRegex.test(vendor)) {
      onAdd(vendor);
    } else {
      setShowError(true);
    }
  }

  function onCancel() {
    if (!!vendor) {
      setDisplayWarningModal(true);
      return;
    }
    onClose();
  }

  if (displayWarningModal) {
    return (
      <WarningModal
        onStay={() => {
          setDisplayWarningModal(false);
        }}
        visible={displayWarningModal}
        onLeave={() => {
          setDisplayWarningModal(false);
          onClose();
        }}
      />
    );
  }

  return (
    <Modal
      width={716}
      closable={false}
      footer={null}
      visible={visible}
      onOk={onAddDomain}
      onCancel={() => onClose()}
      bodyStyle={{
        width: 716,
        backgroundColor: "#353738",
        paddingLeft: 60,
        paddingTop: 40,
        paddingBottom: 50,
        overflowX: "hidden",
      }}
    >
      <div>
        <Typography.Title
          style={{
            display: "inline-block",
            fontFamily: "Montserrat",
            color: "#fff",
            fontWeight: 700,
            fontSize: 34,
            paddingBottom: 20,
            paddingTop: 8,
          }}
        >
          Add Vendor
        </Typography.Title>
        <img
          alt="cross"
          onClick={onCancel}
          src={require("./assets/modal-close.png")}
          className="delete-x-button-connection"
        />
      </div>
      <Input
        value={vendor}
        onChange={(e) => {
          setVendor(e.target.value);
        }}
        className="vendor-input-app"
        placeholder="Domain"
      />
      <div>
        {!!showError && (
          <span className="errorText">{"Please enter a valid domain"}</span>
        )}
      </div>
      <Button
        style={{ display: "block", marginTop: 40, width: 180, height: 50 }}
        type="primary"
        loading={loading}
        className="sign-in"
        onClick={onAddDomain}
      >
        Submit
      </Button>
    </Modal>
  );
}

export function TitleValuePair({ title, value, supportCopy, copyValue }) {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    isCopied &&
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
  }, [isCopied]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <label
        style={{
          color: "rgba(139, 139, 139, 1)",
          fontSize: 14,
          fontWeight: "500",
          fontFamily: "Montserrat",
          lineHeight: "17.07px",
        }}
      >
        {title}
      </label>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {typeof value === "string" && (
          <label
            style={{
              color: "white",
              fontSize: 18,
              fontWeight: "500",
              fontFamily: "Montserrat",
              marginTop: 12,
              lineHeight: "17.07px",
            }}
          >
            {value ?? "-"}
          </label>
        )}
        {typeof value !== "string" && value}
        {supportCopy && !isCopied && (
          <div
            style={{
              marginLeft: 8,
              display: "flex",
              alignItems: "center",
              marginTop: 12,
            }}
          >
            <img
              src={copy_icon}
              onClick={() => {
                window.navigator.clipboard.writeText(copyValue);
                setIsCopied(true);
              }}
              style={{ width: "16px", height: "16px", cursor: "pointer" }}
            />
          </div>
        )}
        {supportCopy && isCopied && (
          <div
            style={{
              marginLeft: 8,
              display: "flex",
              alignItems: "center",
              marginTop: 12,
            }}
          >
            <CheckCircleOutlined style={{ color: "#5c2", fontSize: 16 }} />
          </div>
        )}
      </div>
    </div>
  );
}

export const ShowDeleteConfirm = (props) => {
  const [confirmDeleteModal, setConfimDeleteModal] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const handleOk = () => {
    setDeleteModal(false);
    setConfimDeleteModal("");
  };

  const handleCancel = () => {
    setDeleteModal(false);
    setConfimDeleteModal("");
  };
  return (
    <Tooltip title={"Delete Application"}>
      <div style={{ display: "inline" }}>
        <img
          alt="cross"
          className="delete-btn"
          src={require("./assets/delete-icon.png")}
          onClick={() => setDeleteModal(true)}
        ></img>
        {/* <div className="icon-border">
          <img src={remove_icon} className="delete-btn" />
        </div> */}
        <Modal
          transitionName=""
          maskTransitionName=""
          width={600}
          bodyStyle={{
            width: 600,
            backgroundColor: "#353738",
            paddingLeft: 60,
            paddingTop: 40,
            paddingRight: 0,
            paddingBottom: 60,
            overflowX: "hidden",
          }}
          closable={false}
          footer={null}
          visible={deleteModal}
          onOk={handleOk}
          onCancel={handleCancel}
          className="delete-app-modal-forinput"
        >
          <div>
            <Typography.Title
              style={{
                display: "inline-block",
                fontFamily: "Montserrat",
                color: "#fff",
                fontWeight: 700,
                fontSize: 34,
                paddingBottom: 20,
                paddingTop: 8,
              }}
            >
              Delete account
            </Typography.Title>
            <img
              alt="cross"
              onClick={handleCancel}
              src={require("../src/assets/modal-close.png")}
              className="delete-modal-x-button"
            ></img>
          </div>
          <Typography.Text>
            Please confirm by typing DELETE into the field below:
          </Typography.Text>
          <Input
            placeholder="DELETE"
            value={confirmDeleteModal}
            onChange={(e) => setConfimDeleteModal(e.target.value)}
            className="delete-app-modal-input"
          ></Input>
          <Button
            loading={props.displayLoading}
            disabled={confirmDeleteModal !== "DELETE"}
            style={{ display: "block", marginTop: 40, width: 180, height: 50 }}
            onClick={() => {
              // console.log(props.record);
              props.confirmDelete(props.record);
            }}
            type="primary"
            className="sign-in"
          >
            Delete
          </Button>
        </Modal>
      </div>
    </Tooltip>
  );
};

function App(props) {
  const [tableData, setTableData] = useState();
  const [displayLoading, setDisplayLoading] = useState(false);
  const [showAfterCreateModal, setShowAfterCreateModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedAppDetails, setSelectedAppDetails] = useState(undefined);
  const [identityCodeCopied, setIdentityCodeCopied] = useState(false);
  const [ssoCodeCopied, setSSOCodeCopied] = useState(false);
  const [sensitivitySetting, setSensitivitySetting] = useState(undefined);
  const [sensitivityDomains, setSensitivityDomains] = useState([]);
  const [sensitivityDomainsLoading, setSensitivityDomainsLoading] =
    useState(false);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [sensitivitySettingLoading, setSensitivitySettingLoading] =
    useState(false);
  const [originalSettings, setOriginalSettings] = useState(undefined);
  const [selectedDomainToDelete, setSelectedDomainToDelete] =
    useState(undefined);
  const [vendor, setVendor] = useState("");
  const [domainIndex, setDomainIndex] = useState(-1);
  const [showDeleteForGoogleAuthFile, setShowDeleteForGoogleAuthFile] = useState(false);

  const [googleAuthFile, setGoogleAuthFile] = useState(undefined);

  const googleAuthFileRef = useRef();

  useEffect(() => {
    getSensitivitySettingCall();
    getSensitivityDomainsCall();
    getGoogleAuthStoreCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppDetails]);

  async function getSensitivityDomainsCall() {
    try {
      setSensitivityDomainsLoading(true);
      const response = await getAllDomainsForSensitivitySettings(
        selectedAppDetails.id
      );
      setSensitivityDomains(response.data);
      setSensitivityDomainsLoading(false);
    } catch (error) {
      console.log("Unable to get the domains");
      console.log(error);
      setSensitivityDomainsLoading(false);
    }
  }

  async function newSensitivitySettingsDomainCall(domain, isApplied = true) {
    try {
      setSensitivityDomainsLoading(true);
      await addNewSensitivitySettingDomain(
        selectedAppDetails.id,
        domain,
        isApplied
      );
      setShowVendorModal(false);
      setVendor("");
      getSensitivityDomainsCall();
      setSensitivityDomainsLoading(false);
    } catch (error) {
      console.log("Unable to add the new domain at the moment");
      console.log(error);
      setSensitivityDomainsLoading(false);
    }
  }

  async function deleteDomainCall() {
    try {
      setSensitivityDomainsLoading(true);
      await deleteSensitivitySettingDomain(
        selectedDomainToDelete,
        selectedAppDetails.id
      );
      setSelectedDomainToDelete(undefined);
      getSensitivityDomainsCall();
      setSensitivityDomainsLoading(false);
    } catch (error) {
      console.log("Unable to delete the domain at the moment");
      console.log(error);
      setSensitivityDomainsLoading(false);
    }
  }

  async function updateSettingDomainCall(domain, isApplied = true, id) {
    try {
      setSensitivityDomainsLoading(true);
      await updateSensitivitySettingDomain(
        selectedAppDetails.id,
        domain,
        id,
        isApplied
      );
      getSensitivityDomainsCall();
      setSensitivityDomainsLoading(false);
    } catch (error) {
      console.log("Unable to update the new domain at the moment");
      console.log(error);
      setSensitivityDomainsLoading(false);
    }
  }

  async function getSensitivitySettingCall() {
    try {
      setSensitivitySettingLoading(true);
      const response = await getSensitivitySettingForApp(selectedAppDetails.id);
      setSensitivitySetting(response.data);
      setOriginalSettings(response.data);
      setSensitivitySettingLoading(false);
    } catch (error) {
      console.log("Unable to get the sensitivity setting at the moment");
      console.log(error);
    }
  }

  async function createSensitivitySettingCall(setting) {
    try {
      setSensitivitySettingLoading(true);
      await upsertSensitivitySettingForApp(selectedAppDetails.id, setting);
      getSensitivitySettingCall();
      setSensitivitySettingLoading(true);
    } catch (error) {
      console.log("Unable to upsert the sensitivity setting at the moment");
      console.log(error);
    }
  }

  useEffect(() => {
    ssoCodeCopied &&
      setTimeout(() => {
        setSSOCodeCopied(false);
      }, 1000);
  }, [ssoCodeCopied]);

  useEffect(() => {
    identityCodeCopied &&
      setTimeout(() => {
        setIdentityCodeCopied(false);
      }, 1000);
  }, [identityCodeCopied]);

  useEffect(() => {
    fetchAppList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const generateAPIKey = async (appID) => {
    const newApiKey = await regenerateAPIKey(appID)
    console.log(newApiKey.apiKey);
    setSelectedAppDetails((prevDetails) => ({
      ...prevDetails,
      apiKey: newApiKey.apiKey,
    }));
  }

  const fetchAppList = () => {
    setDisplayLoading(true);
    listAllApplications()
      .then((appList) => {
        setDisplayLoading(false);
        let _apps = Object.assign([], appList.data);
        let appDetailsIndex = -1;
        if (_apps?.length && !!selectedAppDetails) {
          appDetailsIndex = _apps.findIndex((item) => {
            if (item.id === selectedAppDetails.id) {
              return 1;
            }
            return 0;
          });
        }
        if (_apps?.length && (!selectedAppDetails || appDetailsIndex < 0)) {
          setSelectedAppDetails(_apps[0]);
        } else if (selectedAppDetails) {
          const appIndex = _apps.findIndex((item) => {
            if (item.id === selectedAppDetails?.id) {
              return 1;
            }
            return 0;
          });
          appIndex >= 0 && setSelectedAppDetails(_apps[appIndex]);
        }
        setTableData(_apps);
      })
      .catch((error) => {
        setDisplayLoading(false);
        console.log(error);
      });
  };

  function confirmDelete(selectedRecord) {
    setDisplayLoading(true);
    Modal.destroyAll();
    deleteApplication(selectedRecord.id)
      .then((response) => {
        setDisplayLoading(false);
        setSelectedAppDetails(undefined);
        fetchAppList();
      })
      .catch((error) => {
        // alert("Unable to delete the application at the moment");
        setDisplayLoading(false);
        console.log(error);
      });
  }
  const _renderAfterCreateModal = (record) => {
    return (
      <Modal
        transitionName=""
        maskTransitionName=""
        maskStyle={{ backdropFilter: "blur(8px)" }}
        width={776}
        className="appCreatedModal"
        closable={false}
        bodyStyle={{
          paddingTop: 0,
          backgroundColor: "#353738",
          paddingLeft: 60,
        }}
        onOk={() => {
          setShowAfterCreateModal(false);
        }}
        visible={showAfterCreateModal}
        footer={null}
        onCancel={() => setShowAfterCreateModal(false)}
      >
        <div>
          <Typography.Title
            style={{
              display: "inline-block",
              fontFamily: "Montserrat",
              color: "#fff",
              fontWeight: 700,
              fontSize: 44,
              paddingTop: 8,
            }}
          >
            Integration Details
          </Typography.Title>
          <img
            alt="close"
            onClick={() => setShowAfterCreateModal(false)}
            src={require("./assets/modal-close.png")}
            className="new-app-create-modal-x-button"
            alt="app-create-modal-x-button"
          ></img>
        </div>
        <div style={{ marginRight: 60 }}>
          <Typography.Text style={{ paddingRight: 60 }}>
            Please use the following app id and secret for integrating lockr
            APIs and the identity JS Library.{" "}
          </Typography.Text>
        </div>
        <Row align="middle" style={{ height: "100%", marginTop: 16 }}>
          <Col>
            <Typography.Text style={{ fontSize: 18, fontWeight: "400" }}>
              {"App ID : "}
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              strong
              copyable={{
                icon: [
                  <img
                    src={copy_icon}
                    style={{
                      width: "16px",
                      height: "16px",
                      cursor: "pointer",
                      marginLeft: 5,
                    }}
                    key="copy-icon"
                  />,
                  <CheckCircleOutlined
                    style={{ color: "#5c2", fontSize: 16, marginLeft: 5 }}
                    key="copied-icon"
                  />,
                ],
                tooltips: ["Copy", "Copied!!"],
              }}
              style={{ fontSize: 18, fontWeight: "400", marginLeft: 10 }}
            >
              {record?.id}
            </Typography.Text>
          </Col>
        </Row>
        <div style={{ marginTop: 16 }}></div>
        <Row align="middle" style={{ height: "100%" }}>
          <Col>
            <Typography.Text style={{ fontSize: 18, fontWeight: "400" }}>
              {"Secret Key :"}
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              strong
              copyable={{
                text: record?.secretKey,
                icon: [
                  <CopyOutlined
                    style={{ color: "#fff", fontSize: 20, marginLeft: 5 }}
                    key="copy-icon"
                  />,
                  <CheckCircleOutlined
                    style={{ color: "#5c2", fontSize: 20, marginLeft: 5 }}
                    key="copied-icon"
                  />,
                ],
                tooltips: ["Copy", "Copied!!"],
              }}
              style={{ fontSize: 18, fontWeight: "400", marginLeft: 10 }}
            >
              {"**********************"}
            </Typography.Text>
          </Col>
        </Row>
        <Button
          className="sign-in"
          type="primary"
          onClick={() => setShowAfterCreateModal(false)}
          style={{
            width: 160,
            height: 60,
            color: "#61CEF7",
            marginBottom: 20,
            marginTop: 48,
          }}
        >
          OK
        </Button>
      </Modal>
    );
  };

  function cancel() {}

  const renderButton = () => {
    return (
      <Row
        justify="space-between"
        align="middle"
        // style={{ marginTop: 32, marginRight: 91, marginBottom: 60 }}
        className="apiandservices-newapp-btn"
      >
        <Col sm={18} xs={24}>
          <Typography.Title className="apiandservices-heading">
            API & Services
          </Typography.Title>
          <Typography.Title
            // style={{ fontSize: 14, fontFamily: "Montserrat", paddingLeft: 91 }}
            className="apiandservices-sub-heading"
          >
            <Button
              style={{
                background: "transparent",
                color: "#fff",
                marginRight: 40,
                border: "1px solid #353738",
              }}
              onClick={() => {
                window.open(DOCS_URL);
              }}
            >
              <BsBoxArrowUpRight style={{ marginRight: "10px" }} size={12} />{" "}
              API docs
            </Button>
          </Typography.Title>
        </Col>
        <Col sm={6} xs={24} className="createAppButton">
          {/* <Link to="/console/add"> */}
          <NewAppModal fetchAppList={fetchAppList} />
          {/* </Link> */}
        </Col>
      </Row>
    );
  };

  const onEditItemClicked = (selectedRecord) => {
    props.history.push("add", {
      mode: "edit",
      selectedRecord,
    });
  };

  function renderActionsButton(text, record) {
    return (
      <Row justify="space-between">
        <Col xs={24}>
          <div
            style={{
              display: "inline",
              height: 50,
              width: 50,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginRight: 20,
            }}
          >
            {showAfterCreateModal && _renderAfterCreateModal(selectedRecord)}
            <Tooltip title={"Application Details"}>
              <img
                alt="info"
                onClick={() => {
                  setSelectedRecord(record);
                  setShowAfterCreateModal(true);
                }}
                style={{ height: 32, width: 32 }}
                src={require("./assets/info-icon.png")}
              ></img>
            </Tooltip>
          </div>
          <NewAppModal
            record={record}
            mode={"edit"}
            fetchAppList={fetchAppList}
          />
          <Tooltip title={"Delete Application"}>
            <ShowDeleteConfirm
              displayLoading={displayLoading}
              record={record}
              confirmDelete={confirmDelete}
            />
          </Tooltip>
        </Col>
      </Row>
    );
  }

  const columns = [
    {
      title: <span className="app-table-header">App Name</span>,
      key: "name",
      dataIndex: "name",
    },
    {
      title: <span className="app-table-header">App ID</span>,
      key: "id",
      dataIndex: "id",
    },
    {
      title: <span className="app-table-header">Date created</span>,
      key: "createdAt",
      dataIndex: "createdAt",
      render: (date) => moment(date).format("MM/DD/YYYY"),
    },
    {
      width: "20%",
      title: "",
      key: "action",
      align: "right",
      render: renderActionsButton,
    },
  ];

  const renderTable = () => {
    return (
      <div
      // style={{ marginLeft: 32, marginRight: 32, marginTop: 32 }}
      >
        <Table
          rowKey={"id"}
          loading={displayLoading}
          pagination={false}
          className="apiandservices-table"
          dataSource={tableData}
          columns={columns}
        ></Table>
      </div>
    );
  };

  function showDeleteConfirm(record) {
    confirm({
      title: "Are you sure delete this task?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: (e) => {
        confirmDelete(record);
      },
      onCancel: () => {
        cancel();
      },
    });
  }

  function renderApplist() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: "rgba(38, 40, 42, 1)",
          maxWidth: 294,
          minWidth: 200,
          flex: 0.5,
          // maxHeight: "calc(100vh - 410px)",
          overflow: "auto",
          justifyContent: "space-between",
        }}
      >
        <div>
          {tableData?.length &&
            tableData.map((item) => {
              return (
                <>
                  <div
                    onClick={() => {
                      setSelectedAppDetails(item);
                    }}
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #ffffff22",
                      display: "flex",
                      alignItems: "center",
                      padding: 24,
                      backgroundColor:
                        selectedAppDetails?.id === item.id
                          ? "black"
                          : "transparent",
                      borderLeft:
                        selectedAppDetails?.id === item.id
                          ? "3px solid rgba(97, 206, 247, 1)"
                          : "none",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          fontFamily: "Montserrat",
                          lineHeight: "21.94px",
                          color:
                            selectedAppDetails?.id === item.id
                              ? "#61CEF7"
                              : "white",
                        }}
                      >
                        {item.name}
                      </div>
                    </div>
                    <MdChevronRight
                      style={{
                        color:
                          selectedAppDetails?.id === item.id
                            ? "#61CEF7"
                            : "white",
                        fontSize: 22,
                      }}
                    />
                  </div>
                </>
              );
            })}
        </div>
        <div style={{ padding: 24 }}>
          <Button
            type="primary"
            className="new-app-modal-btn"
            style={{ width: "100%" }}
          >
            Add New App
          </Button>
        </div>
      </div>
    );
  }

  async function deleteGoogleAuthStoreCall() {
    try {
      await deleteGoogleAuthFile(selectedAppDetails.id);
      getGoogleAuthStoreCall();
    } catch (error) {
      console.log('Unable to delete the google auth file at the moment');
      console.log(error);
    }
  }

  async function getGoogleAuthStoreCall() {
    try {
      const data = await getGoogleAuthFile(selectedAppDetails.id);
      setGoogleAuthFile(data.data);
    } catch (error) {
      console.log('Unable to get the google auth file at the moment');
      console.log(error);
    }
  }

  async function updateGoogleAuthStoreCall(jsonData, fileName) {
    try {
      await uploadGoogleAuthFile(selectedAppDetails.id, jsonData, fileName);
      getGoogleAuthStoreCall();
    } catch (error) {
      console.log('Unable to upload the google auth file at the moment');
      console.log(error);
    }
  }

  function renderGoogleAuthSettings() {
    return (
      <>
        <div style={{
          background: "#000000",
          minHeight: "140px",
          position: "relative",
          width: "100%",
          borderRadius: 8,
          padding: 16,
          marginTop: 16,
        }}>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ flexDirection: "column", display: "flex" }}>
                <label
                  style={{
                    color: "white",
                    fontWeight: "400",
                    fontFamily: 'Montserrat',
                    fontSize: 14,
                  }}
                >
                  {"Google Custom Authentication"}
                </label>
                <span
                  style={{
                    color: "#8B8B8B",
                    fontSize: 12,
                    fontWeight: '500',
                    fontFamily: 'Montserrat',
                    marginTop: 16,
                  }}
                >
                  lockr Generates the Google Authentication Token for the custom google login, upload the private key to enable it
                </span>
              </div>
              <Button
                type="primary"
                style={{
                  marginLeft: 16,
                  marginRight: 32,
                }}
                className="sign-in vendor-add-url-button"
                onClick={() => {
                  googleAuthFileRef?.current?.click?.();
                }}
              >
                Upload
              </Button>
              <input onChange={handleGoogleFileUpload} accept="application/json" ref={googleAuthFileRef} type="file" style={{ display: 'none' }} />
            </div>
          </div>
          {/* {!!googleAuthFile && <>
            <div
              style={{
                marginTop: 40,
                height: 1,
                width: '100%',
                background: '#353738',
                marginBottom: 40,
              }}
            />
            <span className="sensitivity-setting-domain-title">Your uploaded private key:</span>
            <div onMouseLeave={() => {
              setShowDeleteForGoogleAuthFile(false);
            }} onMouseEnter={() => {
              setShowDeleteForGoogleAuthFile(true);
            }} style={{
              marginTop: 20,
            }}>
              <span style={{
                color: '#50E8B1',
                fontSize: 14,
                fontWeight: '700',
                marginTop: 40,
                fontFamily: 'Montserrat'
              }}>{googleAuthFile.originalName}</span>
              <img onClick={() => {
                deleteGoogleAuthStoreCall();
              }} style={{
                marginLeft: '8px',
                height: '12px',
                cursor: 'pointer',
                visibility: showDeleteForGoogleAuthFile ? 'visible' : 'hidden',
                width: '12px',
              }} alt='delete' src={require('./assets/domain_remove.png')} />
            </div>
          </>} */}
        </div>
      </>
    )
  }

  function handleGoogleFileUpload(event) {
    console.log(event.target.files);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = (e.target.result)
      console.log(text)
      updateGoogleAuthStoreCall(text, file.name)
    };
    reader.readAsText(file);
  }

  function renderSensitivitySettings() {
    return (
      <>
        <div>
          <div
            style={{
              background: "#000000",
              minHeight: "140px",
              position: "relative",
              width: "100%",
              border: "1px solid rgba(53, 55, 56, 1)",
              padding: 20,
              marginTop: 20,
            }}
          >
            {!selectedAppDetails.emailVerificationIntegrated && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: 0,
                  top: 0,
                  borderRadius: 8,
                  opacity: 0.6,
                  zIndex: 1200,
                  background: "black",
                  cursor: "not-allowed",
                }}
              />
            )}
            <div
              style={{
                flexDirection: "column",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 16,
                }}
              >
                <div style={{ flexDirection: "column", display: "flex" }}>
                  <label
                    style={{
                      color: "white",
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: 14,
                    }}
                  >
                    {"Email Verification Sensitivity"}
                  </label>
                  <span
                    style={{
                      color: "#8B8B8B",
                      fontSize: 12,
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      marginTop: 16,
                    }}
                  >
                    Adjust the dial to tighten or loosen the MGE acceptance
                    criteria.
                  </span>
                </div>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flex: 1,
                    paddingRight: 20,
                    flexDirection: "row",
                    gap: 16,
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{
                      width: "400px",
                      position: "relative",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span
                      style={{
                        color:
                          sensitivitySetting?.setting ===
                          SENSITIVITY_SETTINGS.LINIENT
                            ? "#61CEF7"
                            : "transparent",
                        fontSize: 12,
                        position: "absolute",
                        left: -10,
                        top: -26,
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                    >
                      {"Linient"}
                    </span>
                    <span
                      style={{
                        color:
                          sensitivitySetting?.setting ===
                          SENSITIVITY_SETTINGS.SOMEWHAT_LINIENT
                            ? "#61CEF7"
                            : "transparent",
                        fontSize: 12,
                        position: "absolute",
                        left: "35%",
                        top: -26,
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                    >
                      {"Somewhat Linient"}
                    </span>
                    <span
                      style={{
                        color:
                          sensitivitySetting?.setting ===
                          SENSITIVITY_SETTINGS.STRICT
                            ? "#61CEF7"
                            : "transparent",
                        fontSize: 12,
                        position: "absolute",
                        right: -10,
                        fontWeight: "700",
                        fontFamily: "Montserrat",
                        top: -26,
                      }}
                    >
                      {"Strict"}
                    </span>
                    <Slider
                      max={100}
                      disabled={sensitivitySettingLoading}
                      style={{ width: "400px" }}
                      handleStyle={{
                        background: "#61CEF7",
                        borderWidth: "0px",
                        borderRadius: 4,
                        marginTop: -8,
                        width: 10,
                        height: 20,
                      }}
                      tooltipVisible={false}
                      step={50}
                      value={
                        sensitivitySetting?.setting ===
                        SENSITIVITY_SETTINGS.LINIENT
                          ? 0
                          : sensitivitySetting?.setting ===
                            SENSITIVITY_SETTINGS.SOMEWHAT_LINIENT
                          ? 50
                          : 100
                      }
                      onChange={(sliderEvent) => {
                        if (!sensitivitySettingLoading) {
                          setSensitivitySetting(
                            sliderEvent === 100
                              ? { setting: SENSITIVITY_SETTINGS.STRICT }
                              : sliderEvent === 50
                              ? {
                                  setting:
                                    SENSITIVITY_SETTINGS.SOMEWHAT_LINIENT,
                                }
                              : { setting: SENSITIVITY_SETTINGS.LINIENT }
                          );
                        }
                      }}
                    />
                  </div>
                  <Button
                    type="primary"
                    style={{
                      marginLeft: 16,
                    }}
                    disabled={
                      sensitivitySetting?.setting === originalSettings?.setting
                    }
                    loading={sensitivitySettingLoading}
                    className="sign-in vendor-add-url-button"
                    onClick={() => {
                      createSensitivitySettingCall(sensitivitySetting.setting);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
              <div
                style={{
                  marginTop: 40,
                  height: 1,
                  width: "100%",
                  background: "#353738",
                }}
              />
              <span className="sensitivity-setting-domain-title">
                Bypass the email validator settings
              </span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  marginTop: 16,
                }}
              >
                URLs within this are permitted, regardless of the verification
                sensitivity settings.
              </span>
              <span
                style={{
                  marginTop: 40,
                  color: "white",
                  fontSize: 12,
                  fontWeight: "700",
                  fontFamily: "Montserrat",
                }}
              >
                Add custom domains
              </span>
              <div>
                <div
                  style={{
                    marginTop: 16,
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  <Input
                    value={vendor}
                    onChange={(e) => {
                      setVendor(e.target.value);
                    }}
                    className="vendor-input-app"
                  />
                  <span
                    style={{
                      color: "white",
                      opacity: 0.6,
                      fontFamily: "Montserrat",
                      fontSize: "18px",
                      position: "absolute",
                      left: 16,
                      top: 6,
                      zIndex: 1200,
                    }}
                  >
                    xxx@
                  </span>
                  <Button
                    type="primary"
                    loading={sensitivityDomainsLoading}
                    disabled={!domainRegex.test(vendor)}
                    className="sign-in vendor-add-url-button"
                    onClick={() => {
                      newSensitivitySettingsDomainCall(vendor);
                    }}
                  >
                    Add URL
                  </Button>
                </div>
              </div>
              <div
                style={{
                  marginTop: 20,
                  padding: 40,
                  border: "1px solid #171717",
                }}
              >
                <span
                  style={{
                    color: "#50E8B1",
                    fontSize: 14,
                    fontWeight: "700",
                    fontFamily: "Montserrat",
                  }}
                >
                  PERMITTED
                </span>
                <div
                  style={{
                    flexDirection: "row",
                    columnGap: 80,
                    rowGap: 24,
                    flexWrap: "wrap",
                    display: "flex",
                    marginTop: 30,
                    paddingRight: 80,
                  }}
                >
                  {!sensitivityDomains.length && (
                    <div>
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          opacity: 0.5,
                          fontStyle: "italic",
                          columnGap: 12,
                          cursor: "pointer",
                          fontSize: 16,
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                        }}
                      >
                        GoodEmail.com
                      </span>
                    </div>
                  )}
                  {sensitivityDomains?.map?.((item, index) => {
                    return (
                      <div
                        onMouseOut={() => {
                          setDomainIndex(-1);
                        }}
                        onMouseOver={() => {
                          setDomainIndex(index);
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          columnGap: 12,
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            color: domainIndex === index ? "#8B8B8B" : "white",
                            fontSize: 16,
                            cursor: "pointer",
                            fontWeight: "500",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {item.domain}
                        </span>
                        {
                          <img
                            onClick={() => {
                              setSelectedDomainToDelete(item.id);
                            }}
                            style={{
                              height: "12px",
                              cursor: "pointer",
                              visibility:
                                index === domainIndex ? "visible" : "hidden",
                              width: "12px",
                            }}
                            src={require("./assets/domain_remove.png")}
                            alt="delete"
                          />
                        }
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderAppDetails() {
    if (!selectedAppDetails) {
      return <></>;
    }
    return (
      <div
        style={{
          width: "100%",
          flex: 1,
          padding: 24,
        }}
      >
        <div
          style={{
            background: "#000000",
            width: "100%",
            border: "1px solid rgba(53, 55, 56, 1)",
            padding: 20,
            minHeight: "140px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <label
              className="app-list-details-header"
              style={{
                color: "white",
                fontSize: 24,
                fontWeight: "500",
                lineHeight: "24.38px",
              }}
            >
              {selectedAppDetails?.name}
            </label>
            {/* <MdInfo
              onClick={() => {
                setSelectedRecord(selectedAppDetails);
                setShowAfterCreateModal(true);
              }}
              style={{
                color: "white",
                fontSize: 22,
                marginLeft: 16,
                cursor: "pointer",
              }}
            /> */}
            <div
              style={{
                marginLeft: 16,
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <NewAppModal
                record={selectedAppDetails}
                mode={"edit"}
                fetchAppList={fetchAppList}
              />
              <ShowDeleteConfirm
                displayLoading={displayLoading}
                record={selectedAppDetails}
                confirmDelete={confirmDelete}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 40,
              flexDirection: "row",
              display: "flex",
              gap: 40,
            }}
          >
            <TitleValuePair
              title={"App ID"}
              supportCopy
              copyValue={selectedAppDetails?.id ?? ""}
              value={selectedAppDetails?.id}
            />
            <TitleValuePair
              title={"App Secret"}
              supportCopy
              copyValue={selectedAppDetails?.secretKey}
              value={"*".repeat(36)}
            />
            <TitleValuePair
              title={"Date created"}
              value={moment(selectedAppDetails?.createdAt).format("MM/DD/YYYY")}
            />
          </div>
          {selectedAppDetails?.apiIntegration && <div
            style={{
              marginTop: 40,
              display: "flex",
              alignItems: "end",
              gap: 8,
            }}
          >
            <TitleValuePair
              title={"API Key"}
              supportCopy={selectedAppDetails?.apiKey}
              copyValue={selectedAppDetails?.apiKey}
              value={
                selectedAppDetails?.apiKey ? selectedAppDetails?.apiKey.slice(0, 4) +
                  "*".repeat(28) +
                  selectedAppDetails?.apiKey.slice(-4) : 'Generate API Key'
              }
            />
            <div
              style={{
                marginLeft: 8,
                display: "flex",
                alignItems: "center",
                marginTop: 12,
                cursor: "pointer",
                gap: 8,
                color: '#61CEF7'
              }}
              onClick={() => {
                generateAPIKey(selectedAppDetails.id)
              }}
            >
              <img
                src={verifyIcon}
                style={{ width: "16px", height: "16px" }}
              />
              <div>{selectedAppDetails?.apiKey ? 'Regenerate' : 'Generate'}</div>
            </div>
          </div>
          }
        </div>
        <div
          style={{
            background: "#000000",
            width: "100%",
            border: "1px solid rgba(53, 55, 56, 1)",
            padding: 20,
            marginTop: 20,
            minHeight: "140px",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              gap: 40,
            }}
          >
            <TitleValuePair
              title={"Privacy Policy"}
              value={selectedAppDetails?.privacyPolicy}
            />
            <TitleValuePair
              title={"Terms of Usage"}
              value={selectedAppDetails?.termsOfUsage}
            />
          </div>
          <div style={{ marginTop: 40 }}>
            <TitleValuePair
              title={"Event Callback"}
              value={selectedAppDetails?.eventCallback}
            />
          </div>
        </div>
        <div
          style={{
            background: "#000000",
            width: "100%",
            border: "1px solid rgba(53, 55, 56, 1)",
            padding: 20,
            marginTop: 20,
            minHeight: "140px",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              gap: 40,
            }}
          >
            <TitleValuePair
              title={"Authorized Javascript Origins"}
              value={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 12,
                  }}
                >
                  {selectedAppDetails?.javascriptURLs?.map((item) => {
                    return (
                      <label
                        style={{
                          color: "white",
                          fontSize: 14,
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {item.URL}
                      </label>
                    );
                  })}
                </div>
              }
            />
            <TitleValuePair
              title={"Authorized redirect URLs"}
              value={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 12,
                  }}
                >
                  {selectedAppDetails?.callbackURLs?.map((item) => {
                    return (
                      <label
                        style={{
                          color: "white",
                          fontSize: 14,
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          lineHeight: "17.07px",
                        }}
                      >
                        {item.URL}
                      </label>
                    );
                  })}
                </div>
              }
            />
          </div>
        </div>
        <div
          style={{
            background: "#000000",
            width: "100%",
            border: "1px solid rgba(53, 55, 56, 1)",
            padding: 20,
            marginTop: 20,
            minHeight: "140px",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              gap: 40,
            }}
          >
            {/* <label
              style={{
                color: "white",
                fontWeight: "400",
              }}
            >
              {"Alternate Identity Management Integration"}
            </label> */}
            <TitleValuePair
              title={"Alternate Identity Management Integration"}
            />
            <div
              style={{
                position: "relative",
              }}
            >
              <CodeBlock
                language={"html"}
                wrapLongLines
                style={{
                  padding: 24,
                }}
                text={`<!-- Add this line in header tag section of your HTML -->
<meta name="lockr-signin-app_id" content="${selectedAppDetails?.id}" />

<!-- Add this script in head section -->
<script>
const lockrAIM={lockrAIMQueue:lockrAIMQueue=window.lockrAIMQueue||[],setAdditonalData:function(o){"object"==typeof window.lockr&&"function"==typeof window.lockr.additionalData?window.lockr.additionalData(o):lockrAIMQueue.push((()=>window.lockr.additionalData(o)))},processlockrAIMQueue:function(){if("object"==typeof window.lockr&&"function"==typeof window.lockr.additionalData)for(;lockrAIMQueue.length>0;){lockrAIMQueue.shift()()}else setTimeout(lockrAIM.processlockrAIMQueue,100)}};lockrAIM.processlockrAIMQueue();
lockrAIM.processlockrAIMQueue();
</script>

<!-- Add this line before the end of your page body tag section -->
<script src="https://aim.loc.kr/identity-lockr-v1.0.js" />
              `}
                theme={dracula}
                codeBlock
                showLineNumbers={false}
              />
              <div
                style={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                {!identityCodeCopied ? (
                  <img
                    src={copy_icon}
                    onClick={() => {
                      setIdentityCodeCopied(true);
                      window.navigator.clipboard
                        .writeText(`<!-- Add this line in header tag section of your HTML -->
<meta name="lockr-signin-app_id" content="${selectedAppDetails?.id}" />

<!-- Add this script in head section -->
<script>
const lockrAIM={lockrAIMQueue:lockrAIMQueue=window.lockrAIMQueue||[],setAdditonalData:function(o){"object"==typeof window.lockr&&"function"==typeof window.lockr.additionalData?window.lockr.additionalData(o):lockrAIMQueue.push((()=>window.lockr.additionalData(o)))},processlockrAIMQueue:function(){if("object"==typeof window.lockr&&"function"==typeof window.lockr.additionalData)for(;lockrAIMQueue.length>0;){lockrAIMQueue.shift()()}else setTimeout(lockrAIM.processlockrAIMQueue,100)}};lockrAIM.processlockrAIMQueue();
lockrAIM.processlockrAIMQueue();
</script>

<!-- Add this line before the end of your page body tag section -->
<script src="https://aim.loc.kr/identity-lockr-v1.0.js" />`);
                    }}
                    style={{ width: "16px", height: "16px", cursor: "pointer" }}
                  />
                ) : (
                  <CheckCircleOutlined
                    style={{ color: "#5c2", fontSize: 16 }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {!!selectedAppDetails?.aimTags?.length && (
          <div
            style={{
              background: "#000000",
              width: "100%",
              border: "1px solid rgba(53, 55, 56, 1)",
              padding: 20,
              marginTop: 20,
              minHeight: "140px",
            }}
          >
            <TitleValuePair
              title={"AIM UTM Tags"}
              value={
                <>
                  {selectedAppDetails?.aimTags?.map((item) => {
                    return (
                      <label
                        style={{
                          color: "white",
                          fontSize: 14,
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          lineHeight: "17.07px",
                          marginTop: 12,
                        }}
                      >
                        {item.tag}
                      </label>
                    );
                  })}
                </>
              }
            />
          </div>
        )}
        {!!selectedAppDetails?.aimCookies?.length && (
          <div
            style={{
              background: "#000000",
              width: "100%",
              border: "1px solid rgba(53, 55, 56, 1)",
              padding: 20,
              marginTop: 20,
              minHeight: "140px",
            }}
          >
            <TitleValuePair
              title={"AIM First Party Cookie"}
              value={
                <>
                  {selectedAppDetails?.aimCookies?.map((item) => {
                    return (
                      <label
                        style={{
                          color: "white",
                          fontSize: 14,
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          lineHeight: "17.07px",
                          marginTop: 12,
                        }}
                      >
                        {item.cookie}
                      </label>
                    );
                  })}
                </>
              }
            />
          </div>
        )}
        <div
          style={{
            background: "#000000",
            width: "100%",
            border: "1px solid rgba(53, 55, 56, 1)",
            padding: 20,
            marginTop: 20,
            minHeight: "140px",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              gap: 36,
            }}
          >
            <TitleValuePair title={"SSO Integration"} />
            <div
              style={{
                position: "relative",
              }}
            >
              <CodeBlock
                language={"tsx"}
                text={`<meta name="lockr-signin-app_id" content="${selectedAppDetails?.id}" />
<script src="${BASE_URL}/publisher/js/platform.js">
              `}
                theme={dracula}
                codeBlock
                showLineNumbers={false}
              />
              <div
                style={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                {!ssoCodeCopied ? (
                  <img
                    src={copy_icon}
                    onClick={() => {
                      setSSOCodeCopied(true);
                      window.navigator.clipboard
                        .writeText(`<meta name="lockr-signin-app_id" content="${selectedAppDetails?.id}" />
                    <script src="${BASE_URL}/publisher/js/platform.js">`);
                    }}
                    style={{ width: "16px", height: "16px", cursor: "pointer" }}
                  />
                ) : (
                  <CheckCircleOutlined
                    style={{ color: "#5c2", fontSize: 16 }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {renderSensitivitySettings()}
        {/* {renderGoogleAuthSettings()} */}
      </div>
    );
  }

  function renderEmptyState() {
    if (!displayLoading && (tableData?.length ?? 0) <= 0) {
      return (
        <div
          style={{
            marginLeft: 90,
            marginRight: 90,
            minHeight: "50vh",
            background: "black",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="logo_grey" src={require("./assets/logo_grey.png")} />
          <div style={{ marginTop: 16 }} />
          <Typography.Text
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "20px",
              color: "#464646",
              opacity: 0.4,
            }}
          >
            API & Services
          </Typography.Text>
          <div style={{ marginTop: 16 }} />
          <NewAppModal fetchAppList={fetchAppList}></NewAppModal>
        </div>
      );
    }
  }

  return (
    <SideDrawer>
      {renderButton()}
      {tableData?.length && (
        <div
          style={{
            width: "100%",
            paddingLeft: 95,
            paddingRight: 95,
            marginTop: 30,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              background: "rgba(0, 0, 0, 1)",
            }}
          >
            {renderApplist()}
            <div
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              {renderAppDetails()}
            </div>
          </div>
        </div>
      )}
      {renderEmptyState()}
      <ViewApiDocsModal />
      {showAfterCreateModal && _renderAfterCreateModal(selectedRecord)}
      {showVendorModal && (
        <NewVendorForm
          onClose={() => {
            setShowVendorModal(false);
          }}
          visible={true}
          loading={sensitivityDomainsLoading}
          onAdd={newSensitivitySettingsDomainCall}
        />
      )}
      {!!selectedDomainToDelete && (
        <DeleteTypeModal
          visible
          title="Delete"
          handleCancel={() => {
            setSelectedDomainToDelete(undefined);
          }}
          handleOk={deleteDomainCall}
          loading={sensitivityDomainsLoading}
          message={"Are you sure you want to delete the domain?"}
        />
      )}
    </SideDrawer>
  );
}

export default withRouter(App);
