import axios from "axios";
import { DATA_ENDPOINTS, identitProvidersType } from "./Helper";

export const BASE_URL = "https://aim.loc.kr";
// export const BASE_URL = "https://aim-dev.loc.kr";
// export const BASE_URL = "http://localhost:3090";
export const baseUrl = BASE_URL + "/publisher/app/v1";

export const DOCS_URL = `${BASE_URL}/api/lockr_reference.html`;

class ApiOptions {
  constructor(url, method, data, headers, params) {
    this.url = url;
    this.method = method;
    this.data = data;
    this.headers = headers;
    this.params = params;
  }
}

export function getAIMCalls(startDate, endDate, duration) {
  return new Promise(function (resolve, reject) {
    serverCall({
      method: 'POST',
      url: `${baseUrl}/analytics/analytics/aim-calls`,
      data: {
        startDate,
        duration,
        endDate,
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getAIMMapData(startDate, endDate, integrationType = [], eventType = [], supportPageViews = true) {
  return new Promise(function (resolve, reject) {
    serverCall({
      method: 'POST',
      url: `${baseUrl}/analytics/analytics/maps`,
      data: {
        startDate,
        endDate,
        integrationType,
        eventType,
        supportPageViews
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getExternalID() {
  return new Promise(function (resolve, reject) {
    serverCall({
      method: 'GET',
      url: `${baseUrl}/connections/dataEndpoint/publisher-external-id`
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getPublisherAIMDashboard(startDate, endDate, appIDs) {
  return new Promise(function (resolve, reject) {
    serverCall({
      method: 'POST',
      url: `${baseUrl}/uidToken/dashboard/aim-analytics/all`,
      data: {
        startDate,
        endDate,
        appIDs
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getPublisherSettings() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/analytics/publisher-enabled-settings`,
      method: 'POST',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function checkRawID5EnabledForPublisher() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/check-raw-id5-enabled`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function updateRawID5ForPublisher(enabled) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/enable-raw-id5`,
      method: 'POST',
      data: {
        enabled
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getLockrMailPreferenceData(startDate, endDate, appIDs) {
  return new Promise(function (resolve, reject) {
    serverCall({
      method: 'POST',
      url: `${baseUrl}/analytics/lockrMailPreferences`,
      data: {
        startDate,
        endDate,
        appIDs
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getUIDUserGraphData(startDate, endDate, type = identitProvidersType.UID, loginTrendsAppIDs) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/dashboard/graph?type=${type}`,
      method: 'POST',
      data: {
        startDate,
        endDate,
        appIDs: loginTrendsAppIDs
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function publisherHasID5Enabled() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/check-id5-hidden-feature-enabled`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function publisherCheckHiddenFeature(type) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/check-hidden-feature-enabled?type=${type}`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}


export function hasPublisherSeenApiDocs() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/check-is-api-docs-shown`,
      method: 'GET'
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getUserAuthenticationDashboardData(startDate, endDate, type = identitProvidersType.UID, authDataAppIDs) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/dashboard/userAuthentication?type=${type}`,
      method: 'POST',
      data: {
        startDate, endDate,
        appIDs: authDataAppIDs
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getDataEndpointProgress(id) {
  return new Promise(function (resolve, reject) {
    serverCall({
      method: 'GET',
      url: `${baseUrl}/connections/dataEndpoint/get-progress/${id}`,
      data: {},
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function sendMobileOnboardingEmail(email) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${BASE_URL}/publisher/mobileOnboarding`,
      method: 'POST',
      data: { email }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function dataEndpointFullRefresh(id) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/dataEnpoint/fullRefresh/${id}`,
      method: 'POST',
      data: {},
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function upsertIdentityProviderSetting(type = identitProvidersType.UID, dropCookie, dropLocalStorage, keepInIdentityLockr) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/identityProviderSetting/upsert?type=${type}`,
      method: 'POST',
      data: {
        dropCookie,
        dropLocalStorage,
        keepInIdentityLockr
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getIdentityProviderSetting(type = identitProvidersType.UID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/identityProviderSetting/details?type=${type}`,
      method: 'GET'
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function deleteAWSEndpoint(id) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/dataEndpoint/delete/${id}`,
      method: 'DELETE',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function updateSensitivitySettingDomain(appID, domain, id, isApplied) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/sensitivitySetting/appSensitivityDomains/update/${id}`,
      data: {
        appID, domain, id, isApplied
      },
      method: 'POST',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function updateS3DataEndPoint(reqBody, id, type = DATA_ENDPOINTS.AWS) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/dataEndpoint/update/${id}?type=${type}`,
      method: 'POST',
      data: reqBody
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function newS3DataEndPoint(reqBody, type = DATA_ENDPOINTS.AWS) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/dataEndpoint/new-s3-details?type=${type}`,
      method: 'POST',
      data: reqBody,
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function addNewSensitivitySettingDomain(appID, domain, isApplied) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/sensitivitySetting/appSensitivityDomains/new`,
      method: 'POST',
      data: {
        appID, domain, isApplied,
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function deleteSensitivitySettingDomain(id, appID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/sensitivitySetting/appSensitivityDomains/delete/${id}`,
      data: {
        appID
      },
      method: 'POST'
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    });
  })
}

export function getAllDomainsForSensitivitySettings(appID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/sensitivitySetting/appSensitivityDomains/all`,
      method: 'POST',
      data: {
        appID,
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function upsertSensitivitySettingForApp(appID, setting) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/sensitivitySetting/upsertSetting`,
      method: 'POST',
      data: {
        appID, setting
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getS3EndPoints(type = DATA_ENDPOINTS.AWS) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/dataEndpoint/s3-details?type=${type}`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getSensitivitySettingForApp(appID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/sensitivitySetting/getDetails`,
      method: 'POST',
      data: {
        appID,
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}


export function getAllConnectionExecutions(connectionID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/get-executions/${connectionID}`,
      method: 'GET'
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function checkConnectionsTileActive() {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/uidToken/checkConnectionsActive`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getDeletedUIDCredentialsAPI(type = identitProvidersType.UID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/deletedCredentialsByPublisher?type=${type}`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getUIDCredentialsAPI(type = identitProvidersType.UID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/credentialsByPublisher?type=${type}`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getUIDAnalytics(startDate, endDate) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/analytics/${startDate}/${endDate}`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getGoogleAuthFile(appID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      method: 'POST',
      url: `${baseUrl}/googleAuthStore/getGoogleAuthFile`,
      data: {
        appID
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error)
    })
  })
}

export function deleteGoogleAuthFile(appID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      method: 'POST',
      url: `${baseUrl}/googleAuthStore/removeGoogleAuthFile`,
      data: {
        appID
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function uploadGoogleAuthFile(appID, jsonData, fileName) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/googleAuthStore/upsertGoogleAuthFile`,
      method: 'POST',
      data: {
        appID,
        jsonData,
        fileName
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function removeUIDIntegration(type = identitProvidersType.UID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/remove?type=${type}`,
      method: 'DELETE',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getUIDConnectedEmailCount(startDate, endDate, type = identitProvidersType.UID, appIDs) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/count/connectedEmails?type=${type}`,
      method: 'POST',
      data: {
        startDate,
        endDate,
        appIDs: appIDs
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getUIDConsentedUsers(startDate, endDate, appIDs) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/dashboard/consentedUsers`,
      method: 'POST',
      data: {
        startDate,
        endDate,
        appIDs: appIDs
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getUIDUserCount(startDate, endDate, type = identitProvidersType.UID, appIDs) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/count/users?type=${type}`,
      method: 'POST',
      data: {
        startDate,
        endDate,
        appIDs: appIDs
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function upsertUIDCredentialsAPI(accessKey, secretKey, type = identitProvidersType.UID, useLockrKeys) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/uidToken/upsertByPublisher?type=${type}`,
      method: 'POST',
      data: {
        accessKey, secretKey, useLockrKeys
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getDashboardTiles() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/dashboardTiles/all`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function updateUserViewedApiDocs() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/users/viewedApiDocs`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function checkUserCanViewAPIDocs() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/users/checkCanViewApiDocs`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function deleteAWSInfosumBridge() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/infosum-bridge`,
      method: 'DELETE'
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    });
  })
}

export function getReportForGenericConnection(id) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/generate-report/${id}`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function stopGenericConnection(id) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/stop/${id}`,
      method: 'POST'
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function deleteGenericConnection(id) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/delete/${id}`,
      method: 'DELETE'
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function updateGenericConnection(id, integrationType, configuration) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/edit/${id}`,
      method: 'POST',
      data: {
        integrationType, configuration
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getAllGenericConnections(integrationType = 'INFOSUM') {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/all?integrationType=${integrationType}`,
      method: 'GET'
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function createNewGenericConnection(configuration, integrationType) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/new`,
      method: 'POST',
      data: {
        configuration, integrationType
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getS3DetailsForPublisher() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/s3-details`,
      method: 'GET'
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function stopS3SyncForPublisher() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/stop-s3-sync`,
      method: 'POST',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function syncS3Toggle() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/connections/sync-s3-details`,
      method: 'POST',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function submitContactForm(subject, message, selectedCase) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/contact/submitForm`,
      method: 'POST',
      data: {
        subject, message, selectedCase
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getApiLicense() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + "/consentDetails/getConsentDetails")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function stopSnowflakeIntegration(isDelete, integrationId) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/integration/stop-snowflake-integration/${integrationId}`,
      method: 'POST',
      data: {
        isDelete
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function deleteUser(id) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/users/remove/${id}`,
      method: 'DELETE'
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function editSelfUser(reqBody) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/users/edit/self`,
      method: 'PUT',
      data: reqBody
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function editUser(reqBody, id) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/users/edit/${id}`,
      method: 'PUT',
      data: reqBody
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function fetchUserDetails() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/users/details`,
      method: 'GET',
    }).then(response => {
      if (response?.data?.data) {
        resolve(response.data.data);
      } else {
        reject('ROLE_NOT_FOUND');
      }
    }).catch(error => {
      reject(error);
    })
  })
}

export function fetchUserList() {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/users/all`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function resendUserVerificationLink(userId) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/users/resendInvitationEmail`,
      method: 'POST',
      data: {
        userId,
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  });
}

export function newUser(reqBody) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/users/new`,
      method: 'POST',
      data: reqBody
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function syncSnowflakeSnapshot(snowflakeIntegrationId) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/integration/sync-snowflake-snapshot`,
      method: "POST",
      data: {
        snowflakeIntegrationId
      }
    }).then(response => {
      resolve(response.data)
    }).catch(error => {
      reject(error);
    })
  });
}

export function validateSnowflakeCodeAction(code, snowflakeIntegrationId) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/integration/validate-token`,
      method: "POST",
      data: {
        code, snowflakeIntegrationId
      }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  });
}

export function editConnetion(reqBody, connectionId) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/integration/edit/${connectionId}`,
      method: 'POST',
      data: reqBody
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  });
}

export function getAllConnections(type) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: baseUrl + `/integration/all?type=${type}`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  });
}

export function createNewConnection(reqBody) {
  return new Promise(function (resolve, reject) {
    if (reqBody.status) {
      delete reqBody.status;
    }
    serverCall({
      url: baseUrl + '/integration/upsert/v2',
      method: 'POST',
      data: { ...reqBody, appID: null }
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  });
}

export function upsertIntegration(reqBody) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: baseUrl + "/integration/upsert",
      method: "POST",
      data: reqBody,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function syncSnowflake(code, appID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: baseUrl + "/integration/sync-snowflake-batch",
      data: {
        code,
        appID,
      },
      method: "POST",
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getIntegration(appID) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: baseUrl + "/integration/get/" + appID,
      method: "GET",
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deletUser() {
  return new Promise((resolve, reject) => {
    serverCall({ url: baseUrl + "/remove", method: "DELETE" })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changePassword(reqBody) {
  return new Promise((resolve, reject) => {
    serverCall({
      method: "PUT",
      url: baseUrl + "/changePassword",
      data: reqBody,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function editProfile(reqBody) {
  return new Promise((resolve, reject) => {
    serverCall({
      method: "PUT",
      url: baseUrl + "/editProfile",
      data: reqBody,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProfile() {
  return new Promise((resolve, reject) => {
    serverCall({
      method: "GET",
      url: baseUrl + "/getProfile",
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAcceptRejectEmailCounts(from, to, appIDs) {
  return new Promise((resolve, reject) => {
    serverCall({
      method: "GET",
      url: baseUrl + "/analytics/acceptedRejectedEmailCount",
      params: { from: from, to: to, appIDs: appIDs },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getLoginTrend(from, to) {
  return new Promise((resolve, reject) => {
    serverCall({
      method: "GET",
      url: baseUrl + "/analytics/loginTrend",
      params: { from: from, to: to },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getRejectedEmailList(from, to, appIDs) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: baseUrl + "/analytics/getRejectedEmailList",
      method: "GET",
      params: {
        from: from,
        to: to,
        appIDs: appIDs
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getRejectedConsentList(from, to, appIDs) {
  return new Promise((resolve, reject) => {
    serverCall({
      method: "GET",
      url: baseUrl + "/analytics/getRejectedConsentList",
      params: {
        from: from,
        to: to,
        appIDs: appIDs
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getReturningAndNewUsers(from, to, appIDs) {
  return new Promise((resolve, reject) => {
    serverCall({
      method: "GET",
      url: baseUrl + "/analytics/returningAndNewUsers",
      params: {
        from: from,
        to: to,
        appIDs: appIDs
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getRegionWiseConsentMapReport(from, to, appIDs) {
  return new Promise((resolve, reject) => {
    serverCall({
      method: "GET",
      url: baseUrl + "/analytics/regionWiseAcceptance",
      params: {
        from: from,
        to: to,
        appIDs: appIDs
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function downloadEventLogReport(from, to, viewOptions) {
  return new Promise((resolve, reject) => {
    serverCall({
      method: "GET",
      url: baseUrl + "/analytics/downloadEventLogReport",
      params: {
        from: from,
        to: to,
        eventCodes: viewOptions,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getVerticalList() {
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: baseUrl + "/vertical/list",
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAcceptRejectCounts(from, to, appIDs) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: baseUrl + "/analytics/consentAcceptRejectCount",
      method: "GET",
      params: {
        from: from,
        to: to,
        appIDs: appIDs
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTopRegions(from, to, appIDs) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: baseUrl + "/analytics/topRegions",
      method: "GET",
      params: {
        from: from,
        to: to,
        appIDs: appIDs
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDashboardCounts(from, to) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: baseUrl + "/analytics/getCounts",
      method: "GET",
      params: {
        from: from,
        to: to,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getLogsTableData(from, to, page, viewOptions, appIDs) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: baseUrl + "/analytics/getEventLogs",
      method: "GET",
      params: {
        eventCodes: viewOptions,
        from: from,
        to: to,
        page: page,
        size: 8,
        appIDs
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UploadEmailFile(file) {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("file", file);
    serverCall({
      method: "POST",
      url: baseUrl + "/analytics/bulkUploadEmail",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status <= 209) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function getEmailReportTimeChart(id) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/analytics/emailReportTimeChart/${id}`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function getEmailReportPieDetails(id) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/analytics/emailReportDetails/${id}`,
      method: 'GET',
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  });
}

export function getEmailReport(from, to, page) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: baseUrl + "/analytics/emailReport",
      method: "GET",
      params: {
        from: from,
        to: to,
        page: page,
        size: 25,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function handleSignIn(reqBody) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/signin`,
      method: "POST",
      data: reqBody,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function emailRegistered(email) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/check-lockrpublisher-registered?email=${email}`,
      method: "GET",
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function listAllApplications() {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/list`,
      method: "GET",
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addApplication(reqBody) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/create`,
      method: "POST",
      data: reqBody,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function editApplication(applicationId, reqBody) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/edit/${applicationId}`,
      method: "POST",
      data: reqBody,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function acceptPublisherAgreement(data) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/accept-agreement`,
      method: "POST",
      data: data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function register(data) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/signup/`,
      method: "POST",
      data: data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteApplication(applicationId) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/delete/${applicationId}`,
      method: "POST",
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changePasswordForUser(data) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/users/resetPassword`,
      method: 'POST',
      data
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function resetPasswordForUser(data) {
  return new Promise(function (resolve, reject) {
    serverCall({
      url: `${baseUrl}/setUserPassword`,
      method: 'POST',
      data
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export function resetPassword(data) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/setPassword`,
      method: "POST",
      data: data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetUserPassword(data) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/resetPassword`,
      method: "POST",
      data: data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendResetLink(data) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/forgotPassword`,
      method: "POST",
      data: data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function serverCall(options: ApiOptions) {
  return new Promise((resolve, reject) => {
    let _headers = { ...options.headers };
    _headers["authorization"] = localStorage.getItem("authToken");
    options.headers = _headers;
    axios(options)
      .then(async (response) => {
        if (response.data?.error) {
          if (response.data?.error?.code === 401) {
            await refreshAuthToken();
            let _serverResponse = await serverCall(options);
            resolve(_serverResponse);
          } else {
            resolve(response);
          }
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function refreshAuthToken() {
  return new Promise((resolve, reject) => {
    let _refreshToken = localStorage.getItem("refreshToken");
    axios({
      method: "POST",
      url: `${baseUrl}/token/refresh`,
      data: {
        refresh_token: _refreshToken,
      },
    })
      .then((response) => {
        if (response.data?.data?.access_token) {
          localStorage.setItem("authToken", response.data?.data?.access_token);
          resolve();
        } else {
          reject(new Error("ERROR"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function regenerateAPIKey(applicationId) {
  return new Promise((resolve, reject) => {
    serverCall({
      url: `${baseUrl}/apiKey/regenerate/${applicationId}`,
      method: "GET",
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
