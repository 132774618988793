import React, { useRef, useState } from "react";
import {
  Modal,
  Button,
  Typography,
  Row,
  Col,
  Tooltip,
  Input,
  message,
  notification,
  Avatar,
  Checkbox,
  Switch,
} from "antd";
import {
  PlusOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  CopyOutlined,
  CheckCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { addApplication, editApplication } from "../Utils/ApiActions";
import Ribbon from "antd/lib/badge/Ribbon";
import { MacroDetectionSettings } from "./NewAppModalComponents/MacroDetectionSettings";

function MultiURLInput(props) {
  return (
    <Row style={{ marginBottom: 24 }}>
      <Row xs={19} style={{ width: "315px", marginLeft: props.marginLeft }}>
        <Input
          value={props.value}
          style={{ color: "#fff", border: "1px solid #8B8B8B" }}
          onChange={(event) => {
            props?.onChange(event);
          }}
          className="form-input ant-input-40 new-app-modal-input"
          placeholder={props.placeholder}
        ></Input>
      </Row>
      <Row xs={4} style={{ height: 25, marginLeft: 8 }}>
        {/* <Button
          onClick={(event) => {
            props?.onClose(event, props.index);
          }}
          style={{ height: "40px", }}

          type="primary"
          shape="default"
        >
          <CloseOutlined style={{ fontSize: 12 }}></CloseOutlined>
        </Button> */}
        <img
          src={require("../assets/delete-icon.png")}
          className="modal-delete-icon"
          onClick={(event) => {
            props?.onClose(event, props.index);
          }}
        ></img>
      </Row>
    </Row>
  );
}

function AuthorizationButton(props) {
  return (
    <div style={{ marginTop: 24, display: "flex" }}>
      <div
        style={{
          display: "inline",
          marginTop: 5,
          minWidth: props.minWidth ? props.minWidth : "auto",
        }}
      >
        <Typography.Text
          style={{ fontSize: 16, fontWeight: "400", marginBottom: 4 }}
        >
          {props.label}
          {!!props.informationText && (
            <Tooltip title={props.informationText}>
              <InfoCircleOutlined
                style={{
                  marginLeft: 16,
                }}
              />
            </Tooltip>
          )}
        </Typography.Text>
        {/* {props.info && (
          <Tooltip
            title={props.tooltipText ? props.tooltipText : "Sample Tooltip"}
          >
          <InfoCircleOutlined
            style={{ color: "#888888", fontSize: 16, marginLeft: 8, paddingTop: 12 }}
          />
           </Tooltip>
        )} */}
      </div>
      <div style={{ display: "inline-block" }}>
        {props.midContent}
        {props.placeholder && (
          <div
            style={{
              width: "320px",
              display: "inline-block",
              marginLeft: props.marginLeft,
            }}
          >
            <Button
              onClick={(event) => {
                if (props.onClick) {
                  props.onClick(event);
                }
              }}
              type="default"
              style={{
                background: "transparent",
                width: 119,
                height: 40,
                color: "#61CEF7",
                border: "1px solid #61CEF7",
                fontFamily: "Montserrat",
              }}
              className="add-uri-btn"
            >
              {props.placeholder}
            </Button>
          </div>
        )}
        {/* {props.midContent} */}
      </div>
    </div>
  );
}

export function TitleText(props) {
  const [displayPassword, setDisplayPassword] = useState(false);

  return (
    <Row>
      <Row xs={24}>
        <Typography.Text
          style={{
            fontSize: 16,
            fontWeight: "400px",
            marginBottom: 4,
            paddingTop: 7,
          }}
        >
          {props.label}
          {!!props.informationText && (
            <Tooltip title={props.informationText}>
              <InfoCircleOutlined
                style={{
                  marginLeft: 16,
                }}
              />
            </Tooltip>
          )}
        </Typography.Text>
        {
          !!props.errorInfo &&  <Tooltip
          title={props.errorText ? props.errorText : ""}
        >
        <InfoCircleOutlined
          style={{ color: "#f46666", fontSize: 16, marginLeft: 8, paddingTop: 12 }}
        />
         </Tooltip>
        }
        {/* {props.info && (
          <Tooltip
            title={props.tooltipText ? props.tooltipText : "Sample Tooltip"}
          >
          <InfoCircleOutlined
            style={{ color: "#888888", fontSize: 16, marginLeft: 8, paddingTop: 12 }}
          />
           </Tooltip>
        )} */}
      </Row>
      <Row
        xs={24}
        style={{
          width: "365px",
          height: "40px",
          marginLeft: props.marginLeft,
          position: "relative",
        }}
      >
        {props.customInput ? (
          props.customInput
        ) : (
          <Input
            type={props.type}
            disabled={props.disabled}
            value={props.value}
            style={{
              paddingRight: props.supportCopy ? 36 : 8,
              color: "#fff",
              border: "1px solid #8B8B8B",
              cursor: props.disabled
                ? "not-allowed"
                : props.isPointer
                ? "pointer"
                : "auto",
            }}
            onChange={(event) => {
              props?.onChange(event);
            }}
            className={
              "form-input new-app-modal-input ant-input-40 connection-screen-input " +
              (props.supportDisabledColor &&
                props.disabled &&
                "title-text-disabled-style")
            }
            placeholder={props.placeholder || "placeholder"}
            readOnly={props.isReadOnly}
          />
        )}
        {props.supportCopy && (
          <div>
            <img
              alt="copy"
              onClick={() => {
                navigator.clipboard.writeText(props.value);
              }}
              src={require("../assets/copy_icon.png")}
              style={{
                height: 16,
                width: 16,
                cursor: "pointer",
                position: "absolute",
                top: 12,
                right: 16,
              }}
            />
          </div>
        )}
        {/* <input type={props.type}
            disabled={props.disabled}
            value={props.value}
            style={{ color: "#fff",border:"1px solid #8B8B8B",background:'transparent',paddingLeft:16,fontFamily:'Montserrat'}}
            onChange={(event) => {
              props?.onChange(event);
            }}
            
            className="form-input"
            placeholder={props.placeholder || "placeholder"}></input> */}
      </Row>
    </Row>
  );
}

function NewAppModal(props) {
  const [reqBody, setReqBody] = useState({
    name: "",
    eventCallback: "",
    javaScriptOrigins: [],
    authorizedRedirectUrls: [],
    privacyPolicyUrl: "",
    termsOfUseUrl: "",
    aimTags: [],
    aimCookies: [],
    ssoIntegrated: false,
    aimIntegrated: false,
    emailVerificationIntegrated: false,
    pageViews: true,
    apiIntegration: false,

  });
  const [compareReqBody, setCompareReqBody] = useState({
    name: "",
    eventCallback: "",
    javaScriptOrigins: [],
    authorizedRedirectUrls: [],
    privacyPolicyUrl: "",
    termsOfUseUrl: "",
    aimTags: [],
    aimCookies: [],
    ssoIntegrated: false,
    aimIntegrated: false,
    emailVerificationIntegrated: false,
    pageViews: true,
    apiIntegration: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [displayLoading, setDisplayLoading] = useState(false);
  const [showAfterCreateModal, setShowAfterCreateModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const firebaseFileRef = useRef(null);

  const [isSSOIntegrationSelected, setIsSSOIntegrationSelected] =
    useState(false);
  const [isAIMIntegrationSelected, setIsAIMIntegrationSelected] =
    useState(false);
  const [isEmailVerificationSelected, setIsEmailVerificationSelected] =
    useState(false);
  const [isMacroDetectionSelected, setIsMacroDetectionSelected] =
    useState(false);
  const [isPageViewsSelected, setIsPageViewsSelected] =
    useState(false);
  const [isIluiMacroDetectionSelected, setIsIluiMacroDetectionSelected] =
    useState(true);
    const [isApiIntegrationSelected, setIsApiIntegrationSelected] =
    useState(false);

  const [createResponse, setCreateResponse] = useState({});
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const leavePage = () => {
    setWarningModal(false);
    setReqBody({
      name: "",
      eventCallback: "",
      javaScriptOrigins: [],
      authorizedRedirectUrls: [],
      privacyPolicyUrl: "",
      termsOfUseUrl: "",
      aimTags: [],
      aimCookies: [],
      ssoIntegrated: false,
      aimIntegrated: false,
      emailVerificationIntegrated: false,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    if (JSON.stringify(compareReqBody) !== JSON.stringify(reqBody)) {
      setIsModalVisible(false);

      setWarningModal(true);
    } else {
      setReqBody({
        name: "",
        eventCallback: "",
        javaScriptOrigins: [],
        authorizedRedirectUrls: [],
        privacyPolicyUrl: "",
        termsOfUseUrl: "",
        aimTags: [],
        aimCookies: [],
        ssoIntegrated: false,
        aimIntegrated: false,
        emailVerificationIntegrated: false,
      });
      setIsModalVisible(false);
    }
  };

  const onFormKeyUpdated = (key, value) => {
    let _reqBody = Object.assign({}, reqBody);
    _reqBody[key] = value;
    setReqBody(_reqBody);
  };

  const renderWarningModal = () => {
    return (
      <Modal
        centered
        transitionName=""
        maskTransitionName=""
        maskStyle={{ backdropFilter: "blur(8px)" }}
        width={776}
        className="appCreatedModal"
        closable={false}
        bodyStyle={{
          height: 236,
          paddingTop: 0,
          backgroundColor: "#353738",
          paddingLeft: 60,
        }}
        visible={warningModal}
        footer={null}
        onCancel={() => {
          setWarningModal(false);
          setIsModalVisible(true);
        }}
      >
        <div style={{ display: "inline-block", width: 550 }}>
          <Typography.Text>
            You currently have unsaved changes! Are you sure you want to exit
            without saving?
          </Typography.Text>
        </div>
        <div
          style={{
            display: "inline-block",
            position: "absolute",
            marginBottom: 24,
            right: 40,
            cursor: "pointer",
          }}
        >
          <img
            onClick={() => {
              setWarningModal(false);
              setIsModalVisible(true);
            }}
            alt="warning-close"
            src={require("../assets/modal-close.png")}
          ></img>
        </div>
        <div style={{ marginTop: 40 }}></div>
        <Button
          loading={displayLoading}
          style={{ width: 220 }}
          className="sign-in"
          block
          onClick={() => {
            setWarningModal(false);
            setIsModalVisible(true);
          }}
          type="primary"
        >
          <label style={{ cursor: "pointer" }}>{"Stay on this page"}</label>
        </Button>
        <Button
          loading={displayLoading}
          // style={{ width: 220, marginLeft: 24 }}
          // className="sign-in"
          style={{
            marginLeft: 24,
            width: 220,
            height: 56,
            background: "transparent",
            borderColor: "#61CEF7",
            color: "#61CEF7",
            fontFamily: "Montserrat",
            fontSize: 16,
            fontWeight: "bold",
          }}
          block
          onClick={() => {
            leavePage();
          }}
          type="default"
        >
          <label style={{ cursor: "pointer" }}>{"Leave this page"}</label>
        </Button>
      </Modal>
    );
  };

  const closeModal = () => {
    setShowAfterCreateModal(false);

    setIsModalVisible(false);
    // props.history.goBack();
  };

  const _renderAfterCreateModal = () => {
    return (
      <Modal
        maskStyle={{ backdropFilter: "blur(8px)" }}
        width={776}
        className="appCreatedModal"
        closable={false}
        bodyStyle={{
          paddingTop: 0,
          backgroundColor: "#353738",
          paddingLeft: 60,
        }}
        onOk={closeModal}
        visible={showAfterCreateModal}
        footer={null}
        onCancel={closeModal}
      >
        <div>
          <Typography.Title
            style={{
              display: "inline-block",
              fontFamily: "Montserrat",
              color: "#fff",
              fontWeight: 700,
              fontSize: 44,
              paddingTop: 8,
            }}
          >
            OAuth app created
          </Typography.Title>
          <img
            onClick={closeModal}
            src={require("../assets/modal-close.png")}
            className="app-create-modal-x-button"
          ></img>
        </div>
        <div style={{ marginRight: 60 }}>
          <Typography.Text style={{ paddingRight: 60 }}>
            Your app is created! Please use the following app id and secret for
            integrating lockr APIs. The app ID and secret key can be accessed
            later when you select the application on dashboard.{" "}
          </Typography.Text>
        </div>
        <Row align="middle" style={{ height: "100%", marginTop: 16 }}>
          <Col>
            <Typography.Text style={{ fontSize: 18, fontWeight: "400" }}>
              {"App ID : "}
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              strong
              copyable={{
                icon: [
                  <CopyOutlined
                    style={{ color: "#fff", fontSize: 20, marginLeft: 5 }}
                    key="copy-icon"
                  />,
                  <CheckCircleOutlined
                    style={{ color: "#5c2", fontSize: 20, marginLeft: 5 }}
                    key="copied-icon"
                  />,
                ],
                tooltips: ["Copy", "Copied!!"],
              }}
              style={{ fontSize: 18, fontWeight: "400", marginLeft: 10 }}
            >
              {createResponse?.app_id}
            </Typography.Text>
          </Col>
        </Row>
        <div style={{ marginTop: 16 }}></div>
        <Row align="middle" style={{ height: "100%" }}>
          <Col>
            <Typography.Text style={{ fontSize: 18, fontWeight: "400" }}>
              {"Secret Key :"}
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              strong
              copyable={{
                text: createResponse?.secret_key,
                icon: [
                  <CopyOutlined
                    style={{ color: "#fff", fontSize: 20, marginLeft: 5 }}
                    key="copy-icon"
                  />,
                  <CheckCircleOutlined
                    style={{ color: "#5c2", fontSize: 20, marginLeft: 5 }}
                    key="copied-icon"
                  />,
                ],
                tooltips: ["Copy", "Copied!!"],
              }}
              style={{ fontSize: 18, fontWeight: "400", marginLeft: 10 }}
            >
              {"**********************"}
            </Typography.Text>
          </Col>
        </Row>
        <Button
          className="sign-in"
          type="primary"
          onClick={closeModal}
          style={{
            width: 160,
            height: 60,
            color: "#61CEF7",
            marginBottom: 20,
            marginTop: 48,
          }}
        >
          OK
        </Button>
      </Modal>
    );
  };

  function checkValidationV2() {
    let validation = true;
    if (reqBody.ssoIntegrated) {
      if (
        reqBody.name === "" ||
        reqBody.authorizedRedirectUrls.length === 0 ||
        !reqBody.eventCallback ||
        reqBody.javaScriptOrigins.length === 0
      ) {
        validation = false;
      }
    }
    if (reqBody.aimIntegrated) {
      if (reqBody.name === "" || reqBody.javaScriptOrigins.length === 0) {
        validation = false;
      }
    }
    if (reqBody.emailVerificationIntegrated) {
      if (reqBody.name === "") {
        validation = false;
      }
    }
    return validation;
  }

  const _renderSubmitButtons = () => {
    const _navParams = props.location?.state;
    return (
      <Row style={{ marginTop: 48 }}>
        <Col sm={4} xs={21}>
          <Button
            loading={displayLoading}
            style={{ width: 220 }}
            className="sign-in"
            disabled={!checkValidationV2() || displayLoading}
            block
            onClick={() => {
              createNewApplication();
            }}
            type="primary"
          >
            <label style={{ cursor: "pointer" }}>{"Save"}</label>
          </Button>
        </Col>
      </Row>
    );
  };

  function _afterCreateModal(data) {
    setShowAfterCreateModal(true);
    setCreateResponse(data);
  }
  const createNewApplication = () => {
    if (checkValidation()) {
      setDisplayLoading(true);
      let _callBackUrls = [];
      reqBody.authorizedRedirectUrls.map((item) => {
        _callBackUrls.push({
          url: item,
        });
      });
      let _jsUrls = [];
      reqBody.javaScriptOrigins.map((item) => {
        _jsUrls.push({
          url: item?.endsWith("/") ? item.slice(0, -1) : item,
        });
      });
      let aimTags = [];
      reqBody?.aimTags?.map((item) => {
        aimTags.push(item);
      });
      let aimCookies = [];
      reqBody.aimCookies.map((item) => {
        aimCookies.push(item);
      });
      let _navParams = props;
      if (_navParams?.mode === "edit") {
        editApplication(_navParams.record?.id, {
          name: reqBody.name,
          javascriptURLs: _jsUrls,
          eventURL: reqBody.eventCallback,
          aimTags,
          aimCookies,
          callbackURLs: _callBackUrls,
          privacyPolicy: reqBody.privacyPolicyUrl,
          termsOfUsage: reqBody.termsOfUseUrl,
          ssoIntegrated: reqBody.ssoIntegrated,
          aimIntegrated: reqBody.aimIntegrated,
          emailVerificationIntegrated: reqBody.emailVerificationIntegrated,
          macroDetection: isMacroDetectionSelected,
          iluiMacroDetection: isIluiMacroDetectionSelected,
          pageViews: isPageViewsSelected,
          apiIntegration: isApiIntegrationSelected,
        })
          .then((response) => {
            // alert("Application edited successfully");
            // notification.success({
            //   message: "Application edited successfully.",
            // });
            if (props.fetchAppList) {
              props.fetchAppList();
            }
            setDisplayLoading(false);
            setIsModalVisible(false);
            // handleCancel();
            // props.history.goBack();
          })
          .catch((error) => {
            console.log(error);
            setDisplayLoading(false);
            alert("Unable to edit the application at the moment");
          });
      } else {
        addApplication({
          name: reqBody.name,
          javascriptURLs: _jsUrls,
          eventURL: reqBody.eventCallback,
          aimTags,
          aimCookies,
          callbackURLs: _callBackUrls,
          privacyPolicy: reqBody.privacyPolicyUrl,
          termsOfUsage: reqBody.termsOfUseUrl,
          ssoIntegrated: reqBody.ssoIntegrated,
          aimIntegrated: reqBody.aimIntegrated,
          emailVerificationIntegrated: reqBody.emailVerificationIntegrated,
          macroDetection: isMacroDetectionSelected,
          iluiMacroDetection: isIluiMacroDetectionSelected,
          pageViews: isPageViewsSelected,
          apiIntegration: isApiIntegrationSelected,
        })
          .then((response) => {
            // message.success("New application created successfully");
            setReqBody({
              name: "",
              eventCallback: "",
              javaScriptOrigins: [],
              authorizedRedirectUrls: [],
              privacyPolicyUrl: "",
              termsOfUseUrl: "",
              ssoIntegrated: false,
              aimIntegrated: false,
              emailVerificationIntegrated: false,
              pageViews: true,
              apiIntegration: false
            });
            if (props.fetchAppList) {
              props.fetchAppList();
            }
            setDisplayLoading(false);
            setIsModalVisible(false);
            setTimeout(_afterCreateModal(response.data), 500);

            // handleCancel();
          })
          .catch((error) => {
            setDisplayLoading(false);
            // message.error(
            //   "Unable to create the application due to: " + error.message
            // );
          });
      }
    }
  };

  const _renderJSOrigins = () => {
    return (
      <div>
        {reqBody.javaScriptOrigins.map((originItem, index) => {
          // console.log(index);
          return (
            <div style={{ margin: "8px 0px" }}>
              <MultiURLInput
                marginLeft={55} //39
                javaScriptOrigins={reqBody.javaScriptOrigins}
                index={index}
                onChange={(event) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.javaScriptOrigins[index] = event.target.value;
                  setReqBody(_reqBody);
                }}
                onClose={(event, _index) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.javaScriptOrigins.splice(_index, 1);
                  setReqBody(_reqBody);
                }}
                placeholder={"URL"}
                value={originItem}
              ></MultiURLInput>
            </div>
          );
        })}
      </div>
    );
  };

  const _renderRedirectUrls = () => {
    return (
      <div>
        {reqBody.authorizedRedirectUrls.map((originItem, index) => {
          return (
            <div style={{ margin: "8px 0px" }}>
              <MultiURLInput
                marginLeft={82} //70
                authorizedRedirectUrls={reqBody.authorizedRedirectUrls}
                index={index}
                onChange={(event) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.authorizedRedirectUrls[index] = event.target.value;
                  setReqBody(_reqBody);
                }}
                onClose={(event, _index) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.authorizedRedirectUrls.splice(_index, 1);
                  setReqBody(_reqBody);
                }}
                placeholder={"URL"}
                value={originItem}
              ></MultiURLInput>
            </div>
          );
        })}
      </div>
    );
  };

  const checkValidation = () => {
    let _validation = true;
    let _urlRegEx = new RegExp(
      "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})",
      "gmi"
    );
    let _reqBody = Object.assign({}, reqBody);
    if (_reqBody.name.trim() === "") {
      _validation = false;
    }
    // if (_reqBody.javaScriptOrigins.length <= 0) {
    //   _validation = false;
    //   notification.error({
    //     message: "Invalid javascript origins",
    //     description: "Please enter a valid javascript origin",
    //   });
    // }
    // if (_reqBody.authorizedRedirectUrls.length <= 0) {
    //   _validation = false;
    //   notification.error({
    //     message: "Invalid authorization urls",
    //     description: "Please enter a valid authorization urls",
    //   });
    // }
    // if (!_urlRegEx.test(_reqBody.eventCallback)) {
    //   _validation = false;
    //   notification.error({
    //     message: "Invalid event callback url",
    //     description: "Please enter a valid event callback url",
    //   });
    // }
    return _validation;
  };
  const onEditItemClicked = (selectedRecord) => {
    // props.history.push("add", {
    //     mode: "edit",
    //     selectedRecord,
    // });

    let _navParams = props;
    if (_navParams?.mode === "edit") {
      // console.log(_navParams);
      let _jsOrigins = [];
      let _callbackUrls = [];
      let aimTags = [];
      let aimCookies = [];
      if (selectedRecord?.aimCookies?.length > 0) {
        selectedRecord.aimCookies.map((item) => {
          aimCookies.push(item.cookie);
        });
      }
      if (selectedRecord?.aimTags?.length > 0) {
        selectedRecord.aimTags.map((item) => {
          aimTags.push(item.tag);
        });
      }
      if (selectedRecord?.javascriptURLs?.length > 0) {
        selectedRecord.javascriptURLs.map((item) => {
          _jsOrigins.push(item.URL);
        });
      }
      if (selectedRecord?.callbackURLs?.length > 0) {
        selectedRecord.callbackURLs.map((item) => {
          _callbackUrls.push(item.URL);
        });
      }
      if (selectedRecord?.ssoIntegrated) {
        setIsSSOIntegrationSelected(true);
      }
      if (selectedRecord?.aimIntegrated) {
        setIsAIMIntegrationSelected(true);
      }
      if (selectedRecord?.emailVerificationIntegrated) {
        setIsEmailVerificationSelected(true);
      }

      if (selectedRecord?.macroDetection) {
        setIsMacroDetectionSelected(true);
      }
      if (selectedRecord?.pageViews) {
        setIsPageViewsSelected(true);
      }else {
        setIsPageViewsSelected(false);
      }
      if(selectedRecord?.iluiMacroDetection){
        setIsIluiMacroDetectionSelected(true);
      }else {
        setIsIluiMacroDetectionSelected(false);
      }
      if (selectedRecord?.apiIntegration) {
        setIsApiIntegrationSelected(true);
      }else {
        setIsApiIntegrationSelected(false);
      }
      
      setReqBody({
        eventCallback: selectedRecord.eventCallback,
        javaScriptOrigins: _jsOrigins,
        authorizedRedirectUrls: _callbackUrls,
        name: selectedRecord.name,
        aimTags,
        aimCookies,
        privacyPolicyUrl: selectedRecord.privacyPolicy,
        termsOfUseUrl: selectedRecord.termsOfUsage,
        ssoIntegrated: selectedRecord?.ssoIntegrated,
        aimIntegrated: selectedRecord?.aimIntegrated,
        emailVerificationIntegrated:
          selectedRecord?.emailVerificationIntegrated,
      });
      setCompareReqBody({
        eventCallback: selectedRecord.eventCallback,
        javaScriptOrigins: _jsOrigins,
        authorizedRedirectUrls: _callbackUrls,
        name: selectedRecord.name,
        aimTags,
        aimCookies,
        privacyPolicyUrl: selectedRecord.privacyPolicy,
        termsOfUseUrl: selectedRecord.termsOfUsage,
        ssoIntegrated: selectedRecord?.ssoIntegrated,
        aimIntegrated: selectedRecord?.aimIntegrated,
        emailVerificationIntegrated:
          selectedRecord?.emailVerificationIntegrated,
      });
    }

    setIsModalVisible(true);
  };

  function renderGeneralFields() {
    if (
      reqBody.ssoIntegrated ||
      reqBody.aimIntegrated ||
      reqBody.emailVerificationIntegrated
    ) {
      return (
        <>
          <div style={{ marginTop: 32 }} />
          <Typography.Title
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: "white",
              opacity: 0.7,
            }}
          >
            General
          </Typography.Title>
          <TitleText
            marginLeft={224} //217
            value={reqBody.name}
            label={"Name *"}
            informationText={"Name for the application"}
            onChange={(event) => {
              onFormKeyUpdated("name", event.target.value);
            }}
            placeholder={"Add name"}
            info={true}
          />
          {(reqBody.aimIntegrated || reqBody.ssoIntegrated) && (
            <AuthorizationButton
              marginLeft={45} //39
              info={true}
              midContent={_renderJSOrigins()}
              informationText={
                "Domain / URL where the Alternate Identity Management / SSO integrations will be integrated."
              }
              onClick={() => {
                let _reqBody = Object.assign({}, reqBody);
                _reqBody.javaScriptOrigins.push("");
                setReqBody(_reqBody);
              }}
              label={"Authorized javascript origins *"}
              placeholder={"Add URL"}
            ></AuthorizationButton>
          )}
        </>
      );
    }
    return <></>;
  }

  function _renderAIMCookies() {
    return (
      <div>
        {reqBody?.aimCookies?.map((item, index) => {
          return (
            <div style={{ margin: "8px 0px" }}>
              <MultiURLInput
                marginLeft={142}
                javaScriptOrigins={reqBody.aimTags}
                index={index}
                onChange={(event) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.aimCookies[index] = event.target.value;
                  setReqBody(_reqBody);
                }}
                onClose={(event, _index) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.aimCookies.splice(_index, 1);
                  setReqBody(_reqBody);
                }}
                placeholder={"First Party Cookie"}
                value={item}
              />
            </div>
          );
        })}
      </div>
    );
  }

  function _renderAIMTags() {
    return (
      <div>
        {reqBody?.aimTags?.map((item, index) => {
          return (
            <div style={{ margin: "8px 0px" }}>
              <MultiURLInput
                marginLeft={208}
                javaScriptOrigins={reqBody.aimTags}
                index={index}
                onChange={(event) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.aimTags[index] = event.target.value;
                  setReqBody(_reqBody);
                }}
                onClose={(event, _index) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.aimTags.splice(_index, 1);
                  setReqBody(_reqBody);
                }}
                placeholder={"UTM Tag"}
                value={item}
              />
            </div>
          );
        })}
      </div>
    );
  }

  function renderAIMFields() {
    if (reqBody.aimIntegrated) {
      return (
        <>
          <div
            style={{
              marginTop: 32,
              marginRight: 16,
              background: "grey",
              height: 1,
            }}
          />
          <div style={{ marginTop: 32 }} />
          <Typography.Title
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: "white",
              opacity: 0.7,
            }}
          >
            AIM Integration
          </Typography.Title>
          {renderApiIntegrationField()}
          <AuthorizationButton
            marginLeft={205} //70
            info={true}
            informationText={
              "AIM will be triggered from the hash provided within this macro on URLs on the domain(s)."
            }
            onClick={() => {
              let _reqBody = Object.assign({}, reqBody);
              _reqBody.aimTags.push("");
              setReqBody(_reqBody);
            }}
            midContent={_renderAIMTags()}
            label={"UTM Tags"}
            placeholder={"Add"}
          />
          <AuthorizationButton
            marginLeft={142}
            info={true}
            midContent={_renderAIMCookies()}
            onClick={() => {
              let _reqBody = Object.assign({}, reqBody);
              _reqBody.aimCookies.push("");
              setReqBody(_reqBody);
            }}
            informationText={
              "AIM will associate authenticated user records, alternative IDs, and other attributes with the first party cookies defined here."
            }
            label={"First Party Cookie"}
            placeholder={"Add"}
          />
          <div
            style={{
              marginTop: 24,
            }}
          >
            <MacroDetectionSettings
              isMacroDetectionSelected={isMacroDetectionSelected}
              setIsMacroDetectionSelected={setIsMacroDetectionSelected}
              isIuiMacroDetectionSelected={isIluiMacroDetectionSelected}
              setIsIluiMacroDetectionSelected={setIsIluiMacroDetectionSelected}
            />
          </div>
          {/* <AuthorizationButton
            marginLeft={135}
            minWidth={166}
            info={true}
            midContent={
              <>
                <Switch
                  style={{ marginLeft: 150 }}
                  checked={isMacroDetectionSelected}
                  onChange={(checked) => {
                    setIsMacroDetectionSelected(checked);
                  }}
                ></Switch>
              </>
            }
            onClick={() => {
              let _reqBody = Object.assign({}, reqBody);
              _reqBody.aimCookies.push("");
              setReqBody(_reqBody);
            }}
            informationText={
              "If a UTM Tag is not defined, a publisher can choose to have AIM detect any raw email addresses which are included as a macro in the URL string. No need to define the macro, our mechanism will detect a full email address and trigger AIM as long as no consent flags are seen on the page/browser."
            }
            label={"Raw Email"}
          /> */}
          {/* <div
            style={{
              paddingRight: 32,
              marginTop: 4,
              width: 316,
              textAlign: "justify",
            }}
          >
            <Typography.Text
              style={{
                fontSize: 12,
                fontWeight: "400",
                marginBottom: 4,
                color: "#bab8b8",
              }}
              className="sub-text"
            >
              {isMacroDetectionSelected
                ? "Auto-detection will check all macros in the URL for any raw emails that might have been added to campaigns."
                : "Auto-detection is restricted and will not cull raw emails from the URL only those defined by the UTM parameters."}
            </Typography.Text>
          </div> */}
          {/* <Checkbox
              checked={isAIMIntegrationSelected}
              onChange={(event) => {
                setIsAIMIntegrationSelected(event.target.checked);
              }}
            />
            <Typography.Text style={{ marginLeft: 8 }}>
              Alternate Identity Management
            </Typography.Text> */}
        </>
      );
    }
  }

  function renderSSOSpecificFields() {
    if (reqBody.ssoIntegrated) {
      return (
        <>
          <div
            style={{
              marginTop: 32,
              marginRight: 16,
              background: "grey",
              height: 1,
            }}
          />
          <div style={{ marginTop: 32 }} />
          <Typography.Title
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: "white",
              opacity: 0.7,
            }}
          >
            SSO Integration
          </Typography.Title>
          <AuthorizationButton
            marginLeft={72} //70
            info={true}
            informationText={
              "Domain / URL where the SSO integration can be redirected."
            }
            onClick={() => {
              let _reqBody = Object.assign({}, reqBody);
              _reqBody.authorizedRedirectUrls.push("");
              setReqBody(_reqBody);
            }}
            midContent={_renderRedirectUrls()}
            label={"Authorized redirect URLs *"}
            placeholder={"Add URL"}
          ></AuthorizationButton>
          <div style={{ marginTop: 24 }} />
          <TitleText
            marginLeft={118} //113
            value={reqBody.eventCallback}
            onChange={(event) => {
              onFormKeyUpdated("eventCallback", event.target.value);
            }}
            label={"Event callback URL *"}
            placeholder={"URL"}
            informationText={
              "The URL where you want to receive the events from lockr."
            }
            info={true}
          ></TitleText>
          <div style={{ marginTop: 24 }}></div>
          <TitleText
            marginLeft={174} //158
            value={reqBody.privacyPolicyUrl}
            onChange={(event) => {
              onFormKeyUpdated("privacyPolicyUrl", event.target.value);
            }}
            label={"Privacy policy"}
            placeholder={"URL"}
            informationText={"Publisher’s privacy policy."}
            info={true}
          ></TitleText>
          <div style={{ marginTop: 24 }}></div>
          <TitleText
            marginLeft={181} //165
            value={reqBody.termsOfUseUrl}
            onChange={(event) => {
              onFormKeyUpdated("termsOfUseUrl", event.target.value);
            }}
            label={"Terms of use"}
            placeholder={"URL"}
            informationText={"Publisher’s Terms of Use."}
            info={true}
          ></TitleText>
        </>
      );
    }
    return <></>;
  }

  
  function renderPageViewsField() {
    if (!reqBody.aimIntegrated) {
      return <></>;
    }
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 24,
          }}
        >
          <Typography.Text
            style={{ fontSize: 16, fontWeight: "400", marginBottom: 4 }}
          >
            Page Views
          </Typography.Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 220,
              maxWidth: 300,
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: 'center'
              }}
            >
              <Typography.Text
                style={{
                  fontSize: 16,
                  fontWeight: "400",
                  marginBottom: 4,
                }}
              >
                Capture all server-calls
                <Tooltip
                  title={
                    "AIM requires the publisher to define the first-party cookie for the domain(s) in order to function and appropriately append any and all enrichment data from a Connector."
                  }
                >
                  <InfoCircleOutlined
                    style={{
                      marginLeft: 16,
                    }}
                  />
                </Tooltip>
              </Typography.Text>
              <Switch
                style={{ marginLeft: 40 }}
                checked={isPageViewsSelected}
                onChange={(checked) => {
                  setIsPageViewsSelected(checked);
                }}
              ></Switch>
            </div>
            <Typography.Text
              style={{
                maxWidth: 300,
                fontSize: 12,
                fontWeight: "400",
                marginBottom: 4,
                color: "#bab8b8",
              }}
              className="sub-text"
            >
              {"Activating Page View collection will enable features of AIM which enrich and support anonymous visitors."}
            </Typography.Text>
          </div>
        </div>
      </>
    );
  }

  function renderApiIntegrationField() {
    if (!reqBody.aimIntegrated) {
      return <></>;
    }
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 24,
          }}
        >
          <Typography.Text
            style={{ fontSize: 16, fontWeight: "400", marginBottom: 4 }}
          >
            API Integration
          </Typography.Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 190.83,
              maxWidth: 300,
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: 'center'
              }}
            >
              <Typography.Text
                style={{
                  fontSize: 16,
                  fontWeight: "400",
                  marginBottom: 4,
                }}
              >
                Generate API Key
                <Tooltip
                  title={
                    <span>Leverage the API integration to use AIM’s platform in connected TV (cTV) environments or other non-browser instances. For implementation guidance, refer to the detailed API documentation in Visit<a href="https://aim.loc.kr/api-doc/lockr_reference.html" target="_blank"> lockr’s integration guide.</a></span>
                  }
                >
                  <InfoCircleOutlined
                    style={{
                      marginLeft: 16,
                    }}
                  />
                </Tooltip>
              </Typography.Text>
              <Switch
                style={{ marginLeft: 40 }}
                checked={isApiIntegrationSelected}
                onChange={(checked) => {
                  setIsApiIntegrationSelected(checked);
                }}
              ></Switch>
            </div>
            <Typography.Text
              style={{
                maxWidth: 300,
                fontSize: 12,
                fontWeight: "400",
                marginBottom: 4,
                color: "#bab8b8",
              }}
              className="sub-text"
            >
              {"Enable this option to allow your server to pass data directly to AIM via API."}
            </Typography.Text>
          </div>
        </div>
      </>
    );
  }

  return (
    <div style={{ display: props.mode === "edit" && "inline" }}>
      {showAfterCreateModal && _renderAfterCreateModal()}
      {warningModal && renderWarningModal()}
      {props.mode === "edit" ? (
        <Tooltip title={"Edit Application Details"}>
          {/* <Button
          className="edit-btn"
          shape="round"
          icon={<img className="edit-btn-icon" src={require('../assets/setting-icon.png')}></img>}
          onClick={() => {
            onEditItemClicked(props.record);
          }}
          style={{
            borderColor: '#888888',
            backgroundColor: "transparent",
          }}

        ></Button> */}
          <img
            className="edit-btn"
            src={require("../assets/setting-icon.png")}
            onClick={() => {
              onEditItemClicked(props.record);
            }}
            alt="edit-button"
          />
        </Tooltip>
      ) : (
        <Button
          type="primary"
          onClick={showModal}
          className="new-app-modal-btn"
        >
          New App
        </Button>
      )}
      <Modal
        style={{ top: 20, paddingBottom: 20 }}
        transitionName=""
        maskTransitionName=""
        centered
        width={776}
        bodyStyle={{
          minHeight: 686,
          width: 776,
          backgroundColor: "#353738",
          paddingLeft: 60,
          paddingTop: 40,
          paddingRight: 0,
          overflowX: "hidden",
        }}
        maskStyle={{ backdropFilter: "blur(8px)" }}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        // closeIcon={<img src={require('../assets/modal-close.png')}></img>}
      >
        <div>
          <Typography.Title
            style={{
              display: "inline-block",
              fontFamily: "Montserrat",
              color: "#fff",
              fontWeight: 700,
              fontSize: 44,
              paddingBottom: 24,
              paddingTop: 8,
            }}
          >
            {props.mode === "edit" ? "Edit App" : "New App"}
          </Typography.Title>
          <img
            onClick={handleCancel}
            src={require("../assets/modal-close.png")}
            className="new-app-modal-x-button"
          ></img>
        </div>
        <Typography.Title
          style={{
            display: "inline-block",
            fontFamily: "Montserrat",
            color: "white",
            fontWeight: "400",
            fontSize: 24,
          }}
        >
          Integrations
        </Typography.Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 32,
            marginBottom: 32,
          }}
        >
          <div
            style={{
              gap: 8,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={reqBody.ssoIntegrated}
              onChange={(event) => {
                setReqBody((prevState) => ({
                  ...prevState,
                  ssoIntegrated: event.target.checked,
                }));
                // setIsSSOIntegrationSelected(event.target.checked);
              }}
            />
            <Typography.Text style={{ marginLeft: 8 }}>
              SSO Integration
            </Typography.Text>
          </div>
          <div
            style={{
              gap: 8,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={reqBody.aimIntegrated}
              onChange={(event) => {
                setReqBody((prevState) => ({
                  ...prevState,
                  aimIntegrated: event.target.checked,
                }));
                // setIsAIMIntegrationSelected(event.target.checked);
              }}
            />
            <Typography.Text style={{ marginLeft: 8 }}>
              Alternate Identity Management
            </Typography.Text>
          </div>
          <div
            style={{
              gap: 8,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={reqBody.emailVerificationIntegrated}
              onChange={(event) => {
                setReqBody((prevState) => ({
                  ...prevState,
                  emailVerificationIntegrated: event.target.checked,
                }));
              }}
            />
            <Typography.Text style={{ marginLeft: 8 }}>
              Email Verification
            </Typography.Text>
          </div>
        </div>
        {renderGeneralFields()}
        {renderSSOSpecificFields()}
        {renderAIMFields()}
        {renderPageViewsField()}
        {_renderSubmitButtons()}
      </Modal>
    </div>
  );
}

export default NewAppModal;
